<template>
  <v-sheet class="card-size">
    <b-overlay
      :show="show"
      rounded="sm"
      @shown="onShown"
      @hidden="onHidden"
      variant="light"
      opacity="0.9"
      blur="4px"
    >
      <b-card
        :border-variant="borderVariant"
        :footer="numChildContainers > 0 ? 'Card Footer' : ''"
        :footer-class="numChildContainers === 0 ? 'd-none' : ''"
        class="container-card mb-4 card-shadow max-width-lg card-shadow"
        footer-tag="footer"
        tabindex="1"
        @click="getCardDetails(container.id)"
      >
        <div v-if="isAdmin || isFulfillerUser" class="top-right" @click.stop="redirectToEdit">
          <Icon iconName="cog" size="4px" color="grey" />
        </div>
        <template v-slot:header>
          <div>{{ container.name }}</div>
        </template>
        <template>
          <div class="card-center">
            <StatusIcon
              v-if="containerStatus && containerStatus !== 'noDevices' && deviceContainers.length === 0"
              :containerStatus="containerStatus"
              size="10px"
              :containsDescription="true"
            />
            <div style="display: flex; flex-direction: row; flex-wrap: wrap; flex: 1 1;">
              <CardStatus
                v-for="(info, index) in deviceContainers"
                :id="info.container.id"
                :key="info.container.id"
                :index="index"
                :info="info"
                :isDeviceContainer="true"
              />
            </div>
          </div>
        </template>

        <template v-slot:footer>
          <div
            @click.stop="setActiveParent(container.id)"
            v-if="numChildContainers > 0"
          >
            <div class="expand-section">
              {{ $t('containers.expandText') }} {{ numChildContainers }}
              {{ $t('containers.containers') }}
              <span class="expand-icon">
                <Icon
                  icon-name="external-link-square-alt"
                  size="5px"
                  color="#394a8c"
                />
              </span>
            </div>
          </div>
        </template>
      </b-card>
      <template v-slot:overlay>
        <div class="text-center">
          <p id="cancel-label">{{ $t('containers.noDevicesMsg') }}</p>
          <p id="success-label">{{ $t('containers.closeDeviceMsg') }}</p>
          <b-button
            ref="cancel"
            variant="outline-danger"
            size="sm"
            aria-describedby="cancel-label"
            @click="show = false"
          >
            Cancel
          </b-button>
        </div>
      </template>
    </b-overlay>
  </v-sheet>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CardStatus from './CardStatus'
const Icon = () => import('@/components/elements/Icon')
const StatusIcon = () => import('@/components/elements/StatusIcon')

export default {
  name: 'ContainerCard',
  components: { Icon, StatusIcon, CardStatus },
  props: {
    container: Object,
    setActiveParent: {
      type: Function,
      required: false
    }
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    ...mapGetters({
      getRegisteredDevices: 'devices/getRegisteredDevices',
      getContainerInfo: 'getContainerInfo',
      isAdmin: 'login/isAdmin',
      isFulfillerUser: 'login/isFulfillerUser'
    }),
    borderVariant () {
      if (this.containerStatus === 'fallDetected') {
        return 'danger'
      }
      if (this.containerStatus === 'noHeartbeat') {
        return 'warning'
      }
      return ''
    },
    containerDevices () {
      // Left off here
      const currDevices = this.getRegisteredDevices.filter(el => el.container.parentId === this.container.id)
      return currDevices
    },
    containerStatus () {
      return this.getContainerInfo[this.container?.id]?.containerStatus
    },
    containerInfo () {
      return this.getContainerInfo[this.container?.id]
    },
    deviceContainers () {
      return this.containerInfo?.deviceInfo?.deviceContainers
    },
    numChildContainers () {
      // don't count device containers
      return this.container.children.filter(child => {
        return !child.renderingMetadata?.deviceId
      }).length
    }
  },
  methods: {
    ...mapActions({
      setSelectedContainer: 'containers/setSelectedContainer',
      fetchRegisteredDevices: 'devices/fetchRegisteredDevices'
    }),
    redirectToEdit () {
      this.$router.push({
        name: 'EditContainer',
        params: { id: this.container.id }
      })
    },
    getCardDetails () {
      this.setSelectedContainer(this.container?.id)
    },
    onShown () {
      // Focus the cancel button when the overlay is showing
      return this.$refs.cancel?.focus()
    },
    onHidden () {
      // Focus the show button when the overlay is removed
      return this.$refs.show?.focus()
    }
  }
}
</script>

<style scoped>
.container-card {
  min-height: 174px;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
