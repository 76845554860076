<template>
  <div class="">
    <div>
      <v-col class="status-wrapper">
          <div
            :style="[{ background: statusColor }]"
            class="rowstacked"
            :class="!isDeviceContainer && 'cursor-pointer'"
            @click="handleItemClick"
          >
            <div class="icon-padding">
              <LocationIcon
                v-if="roomIcon"
                class="location-icon"
                :location="roomIcon"
                size="8px"
              />
              <small class="small_icons_text">{{ containerName }}</small>
            </div>
            <div class="stacked" style="margin-right: 5px;">
              <WifiIcon :wifiState="getCleanWifi" size="20px"/>
              <span class="small_icons_text">
                {{ info.status.isOffline ?  'offline' : getCleanWifi ? ((Math.round(getCleanWifi) * 100 / 4) + '%') : '' }}
              </span>
            </div>
          </div>
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import WifiIcon from '@/components/elements/WifiIcon'
import LocationIcon from '@/components/elements/LocationIcon'
import statusColorMap from '@/utils/statusColorMap'
import moment from 'moment'

export default {
  name: 'CardStatus',
  components: {
    WifiIcon,
    LocationIcon
  },
  props: {
    id: String,
    index: Number,
    info: Object,
    isDeviceContainer: Boolean
  },
  data () {
    return {
      linkToParent: ''
    }
  },
  computed: {
    ...mapGetters({
      getContainerInfo: 'getContainerInfo'
    }),
    status () {
      const {
        isFall,
        isAbsence,
        isOffline
      } = this.info?.status

      if (isFall) {
        return 'fallDetected'
      } else if (isOffline) {
        return 'noHeartbeat'
      } else if (isAbsence) {
        return 'noPresence'
      } else {
        return 'ok'
      }
    },
    statusColor () {
      return statusColorMap[this.status]
    },
    containerName () {
      const container = this.getContainerInfo[this.id]
      const parent = this.getContainerInfo[container?.parentId]
      if (!this.isDeviceContainer && container?.name && parent?.name) {
        return parent.name + ' / ' + container.name
      }
      return container.name
    },
    roomIcon () {
      return this.getContainerInfo[this.id]?.renderingMetadata?.iconName
    },
    getCleanWifi () {
      const WifiInstant = this.info?.device?.status?.WIFI?.instant
      if (WifiInstant) {
        const lastHeard = moment(WifiInstant)
        const beating = moment().diff(lastHeard, 'seconds') < 125

        if (beating) return this.info?.device?.status?.WIFI?.signalStrength
      }
      // temporary fix for unreliable device status WIFI property in dev data
      // It should really return the latest WIFI signalStrength no matter if status is anything else
      return this.status === 'noHeartbeat' ? 0 : this.info?.device?.status.WIFI?.signalStrength ? this.info?.device?.status.WIFI?.signalStrength : 4
    }
  },
  methods: {
    ...mapActions({
      toggleSlideDrawer: 'containers/toggleSlideDrawer',
      clearSelectedContainer: 'containers/clearSelectedContainer',
      setSelectedContainer: 'containers/setSelectedContainer'
    }),
    setLinkToParent () {
      if (!this.isDeviceContainer) {
        this.linkToParent = '/containers/' + this.info?.container?.parentId
      }
    },
    handleItemClick () {
      if (!this.isDeviceContainer) {
        this.setSelectedContainer(this.info?.container?.parentId)
      }
    }
  },
  mounted () {
    this.setLinkToParent()
  }
}
</script>
